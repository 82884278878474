import React from "react";
import toast from "react-hot-toast";
import { Route, Redirect } from "react-router-dom";

const RouteGuard = ({ component: Component, ...rest }) => {
  function hasJWT() {
    const token = localStorage.getItem("token");
    if (token) {
      const payload = JSON.parse(atob(token.split(".")[1]));
      const isExpired = payload.exp * 1000 < Date.now();

      if (!isExpired) {
        return true;
      } else {
        localStorage.removeItem("token");
        toast.error("Session expired. Please login to continue.");
        return false;
      }
    }
    return false;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        hasJWT() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: `?redirect=${encodeURIComponent(
                props.location.pathname
              )}`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default RouteGuard;
